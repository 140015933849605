<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
        <div class="card-header">
            <h4>
                تقييم الطلاب <button v-b-modal.new @click="point=0" style="border-radius: 50%; width: 30px; height: 30px; padding-right: 9px" class="btn btn-sm btn-primary">
                    <i class="fa fa-plus"></i>
                </button>
            </h4>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الفصل
                        </th>
                        <th>
                            التقييم
                        </th>
                        <th>
                            حذف
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="r in rates" :key="r._id">
                            <td>
                                {{ r.student_name }}
                            </td>
                            <td>
                                {{ r.date }}
                            </td>
                            <td>
                                {{ r.classroom }}
                            </td>
                            <td>
                                {{ r.content }}
                            </td>
                            <td>
                                <button class="btn btn-outline-danger btn-sm" @click="remove(r._id)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="new" title="تقييم طالب" hide-footer>
        <div class="form-group">
          <h5 for="">اختر الطالب</h5>
          <select class="form-control" v-model="selected">
            <option value="">-- اختر الطالب --</option>
            <option v-for="student in students" :key="student.number" :value="student.number">
                ({{ student.classname }} - {{student.classroom}}) {{ student.name }}
            </option>
          </select>
        </div>
        <div class="form-group" v-if="point != 0">
          <h5 for="">اختر التقييم</h5>
          <select class="form-control" v-model="rate">
            <option value="">-- اختر التقييم --</option>
            <option v-for="(r) in ss[point == 1 ? 1 : 2]" :key="r" :value="r">
                {{ r }}
            </option>
          </select>
        </div>
        <div class="row" v-if="point == 0">
            <div class="col-12 col-lg-6 g">
                <button class="btn btn-block btn-success" @click="point=1">
                    تقييم ايجابي
                </button>
            </div>
            <div class="col-12 col-lg-6 g">
                <button class="btn btn-block btn-danger" @click="point=-1">
                    تقييم سلبي
                </button>
            </div>
        </div>
        <div class="col-12 text-center g" v-if="point != 0">
            <button class="btn btn-primary" @click="save()">
                <i class="fa fa-check"></i>
                حفظ التقييم
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            oo: [
                "1"
            ],
            students: [],
            selected: "",
            q: "",
            rate: "",
            rates: [],
            point: 0,
            ss: {
                1: [
                    "مشاركة",
                  "حل تمارين الفصل",
                  "كتابة مع المعلم",
                  "مميز",
                  "متعاون",
                  "مؤدب",
                  "حفظ المطلوب",
                  "متفاعل في مدرستي",
                  "حل الواجب"
            ], 2: [
                "تأخر عن الحصة",
                  "نسيان الكتاب",
                  "نوم اثناء الشرح",
                  "حديث جانبي",
                  "عدم الانتباه للدرس",
                  "الاكل والشرب داخل الفصل",
                  "مضايقة زميل",
                  "اعاقة سير الدرس",
                  "اتأذان لدورة المياه",
                  "تحصيلي",
                  "عدم المشاركة",
                  "عدم حل الواجب",
                  "عدم الكتابة مع المعلم",
                  "ضعف في الاختبارات",
            ]
            },
            teacher: JSON.parse(localStorage.getItem('teacher')),
        }
    },
    created(){
        var g = this;
        g.getStudents()
        g.getRates()
    },
    methods: {
        remove(r){
            if(confirm("متأكد من الحذف؟")){
                var g = this;
                $.post(api + '/teacher/rates/delete', {
                    username: this.teacher.username,
                    password: this.teacher.password,
                    id: r
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getRates()
                    }
                }).fail(function(){
                })
            }
        },
        getRates(){
            var g = this;
            $.post(api + '/teacher/rates', {
                username: this.teacher.username,
                password: this.teacher.password,
                q: this.q
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.rates = r.response;
                }
            }).fail(function(){
            })
        },
        save(){
            var g = this;
            if(!g.selected || g.selected == "" || !g.rate || g.rate == ""){
                return alert("برجاء اختيار الطالب والتقييم", 200)
            }
            $.post(api + '/teacher/rate', {
                username: this.teacher.username,
                password: this.teacher.password,
                number: g.selected,
                rate: g.rate,
                point: g.point
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم الحفظ بنجاح", 100)
                    g.getRates()
                    $("#new___BV_modal_header_ > button").click()
                }
            }).fail(function(){
            })
        },
        getStudents(){
            var g = this;
            $.post(api + '/teacher/mystudents', {
                username: this.teacher.username,
                password: this.teacher.password,
                q: this.q
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response;
                }
            }).fail(function(){
            })
        },
    }
}
</script>

<style>

</style>